@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--topbarHeight));
  padding: 0;

  @media (--viewportMedium) {
    height: 100vh;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.sections {
  margin: 0;
}

.section {
}

/* Select first section */
.section:nth-child(1) {
  background-color: var(--marketplaceColorLight);
}

.sectionContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 24px;
}

.sectionContentFirstChild {
  composes: sectionContent;
}

.sectionHowItWorks {
  max-width: none; /* Remove max-width as we are using full width */
  padding: 0;
}

.sectionNewsletter {
  padding-top: 48px;
  padding-bottom: 96px;
}
